import { getRxStorageIndexedDB } from 'rxdb-premium/plugins/storage-indexeddb'
import {
  CommonHelper,
  LocalDatabaseManager
} from '@digitalworkflow/dwtranslateclient'
import { AuthService } from '@digitalworkflow/dwloginclient'

const getAuthToken = async (): Promise<string> => {
  const authService = new AuthService()
  AuthService.setPortalLoginId(process.env.REACT_APP_PORTAL_LOGIN_ID ?? '')

  const validResult = await authService.authUserFromUsername(
    process.env.REACT_APP_PORTAL_LOGIN_EMAIL ?? '',
    process.env.REACT_APP_PORTAL_LOGIN_PASSWORD ?? ''
  )

  if (validResult.is_error) {
    if (validResult.is_error) throw new Error('Authentication failed')
  }

  return validResult.data.authToken ? validResult.data.authToken : ''
}

const getLocalAuthToken = () => {
  return localStorage.getItem('dwportal_auth_token')
}

/**
 * Initiates synchronization of the local database with the server.
 * @param setWidth Function to set the synchronization progress as a percentage.
 * @param setSyncing Function to set the syncing state (true/false).
 */
export const syncDatabase = async (
  setIsSyncing: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  CommonHelper.setEnvironment(process.env.REACT_APP_PSL_ENV as any)

  CommonHelper.setProjectName(process.env.REACT_APP_PSL_PROJECT_NAME || 'dw')

  const instance = LocalDatabaseManager.instance()
  if (instance.db && !instance.db.destroyed) {
    return
  }

  let token = getLocalAuthToken()

  if (token === undefined || token == null || token === '') {
    token = await getAuthToken()
  }

  await LocalDatabaseManager.startDb(getRxStorageIndexedDB(), token)

  const totalCollections = instance.getCollectionsWithReplication()
  let collections: string[] = totalCollections.map((collection) =>
    collection.getCollectionName()
  )
  const syncEvents = instance.evDatabaseSyncProgress()

  syncEvents.colletionSyncComplete$.subscribe((collection: string) => {
    if (collection) {
      // console.log('collection Sync complete: ', collection)
      collections = collections.filter((c) => c !== collection)
    }
  })

  syncEvents.initialPullComplete$.subscribe(async (isComplete: boolean) => {
    if (isComplete) {
      console.log('Initial pull replication is complete.')
      setIsSyncing(false)
      await instance.isBusy()
    }
  })

  syncEvents.error$.subscribe((error: any) => {
    if (error !== null && error !== undefined) {
      console.error('Replication error:', error)
    }
  })

  await instance.isBusy()
}
