/* eslint-disable camelcase */
import React, { useState } from 'react'
import SelectComponent from './SelectComponent'

function BuildingSelection({ setPresentBuildingId }: any) {
  const [chosenBuilding, setChosenBuilding] = useState<string>('')

  const [buildingsList] = useState([
    {
      label: 'BLHOHO016_417514thAve',
      value: 'BLHOHO016_417514thAve'
    },
    {
      label: 'B21098_33King',
      value: 'INO01932'
    },
    {
      label: 'Test Building 1',
      value: 'testBuilding1'
    }
  ])

  const handleBuildingChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedBuildingId = e.target.value

    if (selectedBuildingId) {
      setChosenBuilding(selectedBuildingId)
      setPresentBuildingId(selectedBuildingId)
    }
    console.log('building selection: ', selectedBuildingId)
  }

  return (
    <div>
      <div style={{ width: '25%' }}>
        <div className='title'>Select Building</div>
        <SelectComponent
          value={chosenBuilding}
          onChange={handleBuildingChange}
          parentClassName='mb-3'
          label=''
          items={buildingsList}
        />
      </div>
    </div>
  )
}

export default BuildingSelection
