import React, { useEffect, useState } from 'react'
import { SBFloorPlan } from '@digitalworkflow/sbpcommon'
import BuildingSelection from './BuildingSelection'
import { syncDatabase } from '../utils/databaseFunctions'

const SbFloor = () => {
  const [isSyncing, setIsSyncing] = useState(true)
  const [presentBuildingId, setPresentBuildingId] = useState('')

  useEffect(() => {
    syncDatabase(setIsSyncing)
  }, [])
  return !isSyncing ? (
    <>
      {presentBuildingId === '' ? (
        <BuildingSelection setPresentBuildingId={setPresentBuildingId} />
      ) : (
        <>
          <button
            onClick={() => setPresentBuildingId('')}
            style={{
              background: '#556ee6',
              border: '1px solid #556ee6',
              borderRadius: '0.25rem',
              color: '#FFF',
              padding: '5px 10px'
            }}
          >
            Go Back to Building Selection
          </button>
          <SBFloorPlan buildingId={presentBuildingId} editMode />
        </>
      )}
    </>
  ) : (
    <h1>Syncing1</h1>
  )
}
export default SbFloor
